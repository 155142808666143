import { createGlobalStyle } from "styled-components";

// To display styling for wallet adapter react UI
require("@solana/wallet-adapter-react-ui/styles.css");

// Global Styling For App
export const GlobalStyle = createGlobalStyle`
  .app {
    display: flex;
    width: 100%;
    background-color: var(--neutral-blue-900);
  }

  .app-empty-top {
    width: 100%;
    height: 56px;
  }

  .app-empty-left {
    width: 220px;
    min-width: 220px;
    max-width: 220px;
    height: 100%;
  }

  @media screen and (max-width: 999060px) {
    .app-empty-left {
      width: 72px;
      min-width: 72px;
      max-width: 220px;
      height: 100%;
    }
  }

  a[target=_blank] {
    cursor: pointer;

    :hover {
      text-decoration: none;
    }

    :after {
      margin: 0px 3px 0px 5px;
      filter: invert(1);
    }
  }
`;
