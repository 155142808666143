export enum Environment {
  Production,
  Staging,
  Development,
}

export const detectEnvironment = (): Environment => {
  const environment = process.env.APP_ENV || process.env.NEXT_PUBLIC_APP_ENV;
  console.log(`Environment: ${environment}`);
  if (!environment) return Environment.Development;

  switch (environment) {
    case "prod":
      return Environment.Production;
    case "staging":
      return Environment.Staging;
    default:
      return Environment.Development;
  }
};
