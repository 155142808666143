const NoScript = () => {
  return (
    // <!-- Google Tag Manager (noscript) -->
    <noscript
      dangerouslySetInnerHTML={{
        __html: `iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T2QB8F7"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
      }}
    />
    // <!-- End Google Tag Manager (noscript) -->
  );
};

export default NoScript;
