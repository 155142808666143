import React, { useState } from "react";

export interface AppContextInterface {
  lastUpdatedAt: Date | null;
  handleResponse: (args?: any) => void;
}

const defaultAppContext: AppContextInterface = {
  lastUpdatedAt: null,
  handleResponse: (args?: any) => null,
};

export const AppContext = React.createContext(defaultAppContext);

interface AppContextProviderProps extends React.HTMLProps<HTMLElement> {}

export function AppContextProvider(props: AppContextProviderProps) {
  const [lastUpdatedAt, setLastUpdatedAt] = useState<Date | null>(
    defaultAppContext.lastUpdatedAt,
  );

  const handleResponse = (args: any) => {
    if (args.asOf && (!lastUpdatedAt || args.asOf > lastUpdatedAt)) {
      setLastUpdatedAt(args.asOf || null);
    }
  };

  return (
    <AppContext.Provider value={{ lastUpdatedAt, handleResponse }}>
      {props.children}
    </AppContext.Provider>
  );
}
