import Script from "next/script";
import { DynamoEnvKeys } from "src/context/configContext";
import { useDynamoConfig } from "src/hooks/useDynamoConfig";

const HelloMoonEventManager = () => {
  const allowUserAnalytics = useDynamoConfig({
    key: DynamoEnvKeys.USER_ANALYTICS,
  });

  return (
    <>
      {allowUserAnalytics?.BOOL ? (
        <Script type="module" src="/scripts/userDataManager.js" />
      ) : null}
    </>
  );
};

export default HelloMoonEventManager;
