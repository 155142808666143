import { useRouter } from "next/router";
import Head from "next/head";

const HeadSection = () => {
  const router = useRouter();
  const locationPathName = router.asPath.toLowerCase();
  const currentUrl = "http://www.hellomoon.io" + locationPathName;
  const image = {
    url: "https://public-images-hellomoon.s3.us-east-2.amazonaws.com/hello-moon-preview-v2.png",
    height: 647,
    width: 1293,
    type: "image/png",
  };
  const hashtag = "#hellomoon";
  const quote = "Look what I found on HelloMoon!";

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="website" />
      <meta property="url" content={currentUrl} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#17181A" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
      <meta name="quote" content={quote} />
      <meta name="image" content={image.url} />
      <meta name="og:locale" content="en_US" />
      <meta name="og:type" content="website" />
      <meta name="og:quote" content={quote} />
      <meta name="og:hashtag" content={hashtag} />
      <meta name="og:image" content={image.url} />
      <meta name="og:image:type" content={image.type} />
      <meta name="og:image:height" content={image.height?.toString()} />
      <meta name="og:image:width" content={image.width?.toString()} />
      <meta name="og:image:secure_url" content={image.url} />
      <meta name="og:url" content={currentUrl} />
      <meta name="og:site_name" content="Hello Moon" />

      <meta name="twitter:image" content={image.url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@HelloMoon_io" />
      <meta name="twitter:creator" content="@HelloMoon_io" />
    </Head>
  );
};

export default HeadSection;
