import { AttributeValue } from "@aws-sdk/client-dynamodb";
import { useContext, useEffect, useState } from "react";
import { DynamoConfigContext, DynamoEnvKeys } from "src/context/configContext";

interface DynamoConfig {
  key: DynamoEnvKeys;
}

export const useDynamoConfig = ({
  key,
}: DynamoConfig): AttributeValue | undefined => {
  const [config, setConfig] = useState<AttributeValue | undefined>();
  const configContext = useContext(DynamoConfigContext);

  useEffect(() => {
    const config = configContext?.config;
    const value = config?.find((configEle) => configEle.key.S === key);
    if (value) {
      setConfig(value.value);
    }
  }, [configContext?.config, key, setConfig]);

  return config;
};
