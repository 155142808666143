import Head from "next/head";
import Script from "next/script";

const HeaderIndex = () => {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta name="theme-color" content="#17181A" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Hello Moon: On-chain Solana NFT & DeFi Insights"
        />
        <meta
          name="twitter:description"
          content="Hello Moon provides innovative datasets on Solana to empower NFT & DeFi investors & traders. Explore & track top NFT projects, monitor wash trading & social (influencers and whales) activity, and swap DeFi tokens for free on HelloMoon.io"
        />
        <meta name="twitter:site" content="@HelloMoon_io" />
        <meta name="twitter:creator" content="@HelloMoon_io" />
        <meta
          name="twitter:image"
          content="https://public-images-hellomoon.s3.us-east-2.amazonaws.com/hello-moon-preview-v2.png"
        />

        {/* manifest.json provides metadata used when your web app is installed on a
      user's mobile device or desktop. See https://developers.google.com/web/fundamentals/web-app-manifest/ */}
        <link rel="manifest" href="/manifest.json" />

        {/* Load font asynchronously */}
        {/* See: https://stackoverflow.com/questions/64121456/next-js-how-to-add-a-link-tag-inside-the-head-with-literal-onload-attribut */}
        {/* See: https://pagespeedchecklist.com/asynchronous-google-fonts */}
        <link
          rel="preconnect"
          href="https://fonts.googleapis.com"
          crossOrigin="true"
        />
        <link
          rel="preload"
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
          as="style"
        />
        {/* We open a style tag, and by using dangerouslySetInnerHTML close it. Then we insert the link tag with the
            needed onload listener. Then, we open a style tag so that it is closed by the JSX </style> tag at the end. */}
        <style
          dangerouslySetInnerHTML={{
            __html: `
            </style>
              <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
                media="print"
                onload="this.onload=null;this.removeAttribute('media');"
              />
            <style>`,
          }}
        ></style>

        <title>
          Hello Moon | Solana Analytics | NFTs | DeFi | SOL | Universal Index
        </title>
      </Head>
      {/* Google Tag Manager - Start */}
      <link rel="preconnect" href="https://www.googletagmanager.com" />
      <link rel="preconnect" href="https://www.google-analytics.com" />
      <Script id="gtm" strategy="afterInteractive">{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-T2QB8F7');`}</Script>
      {/* Google Tag Manager - End */}
    </>
  );
};

export default HeaderIndex;
