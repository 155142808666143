import { Environment, detectEnvironment } from "./environment";
import { ConfigurationOptions } from "./options";
import {
  DevelopmentConfiguration,
  StagingConfiguration,
  ProductionConfiguration,
} from "./environments";

const Configuration = (environment?: Environment): ConfigurationOptions => {
  if (environment === undefined) {
    environment = detectEnvironment();
  }

  switch (environment) {
    case Environment.Development:
      return DevelopmentConfiguration;
    case Environment.Staging:
      return StagingConfiguration;
    case Environment.Production:
      return ProductionConfiguration;
    default:
      return DevelopmentConfiguration;
  }
};

export default Configuration;
