import { ConfigurationOptions } from "../options";

export const Configuration: ConfigurationOptions = {
  AWS: {
    CONFIG_BUCKET: "hellomoon-dev-config",
    ELASTICACHE_MEMCACHED_NODES: [
      "chalice-cache.ojticy.0005.use2.cache.amazonaws.com:11211",
    ],
    REDIS_CLUSTERS: [
      "redis://dev-candlestick-redis-cluster.ojticy.clustercfg.use2.cache.amazonaws.com:6379",
    ],
    REDIS_MODE: "",
  },
  CACHE: {
    EXCLUSIONS: "flags$tradingView$latest",
    FORCE_TIMEOUT_AFTER_MS: 1000,
    LIFETIME_SECONDS: 15,
  },
  DISCORD: {
    CLIENT_ID: "1106300500195954838",
    REDIRECT_URI: "/connect/discord/callback",
    ROVE_DISCORD_EVENTS_QUEUE_URL:
      "https://sqs.us-east-2.amazonaws.com/601699586334/dev-RoveDiscordRoleEventsQueue",
  },
  NEXT_PUBLIC: {
    API_URL: "https://brew.hellomoon.io",
    BROWSER_ENV: "dev",
    COGNITO_USER_POOL: "us-east-2_Vb4zeaS0M",
    COGNITO_USER_POOL_ID: "485k40n15pdi56nm1tqvp89pk2",
    README_API_URI: "https://readme-api-key.hellomoon.io",
    RECAPTCHA_KEY: "6Lc2gOkjAAAAAA5YoqcVPMswACZUp7_nd3MEwhZJ",
    SENTRY_DSN:
      "https://0986ab5f34744cf599795c6aecbef0eb@o1378324.ingest.sentry.io/6690114",
  },
  SITE: {
    CORS_ORIGIN: "https://dev.hellomoon.io",
    RPC_ENDPOINT: "",
    SKIP_GET_STATIC_PATHS: false,
  },
  STRIPE: {
    PUBLISHABLE_KEY: "",
    PRODUCTS: [
      "prod_NCSGO41Ju7zqbX",
      "prod_NCRsWEec1fD6iY",
      "prod_NCRqapKQdo3Unj",
    ],
    BUSINESS: {
      ANNUAL: "price_1MS2x3D0KT7zVwoYl23WIRmy",
      METERED: "price_1MS2x3D0KT7zVwoYvRKgkgvx",
      MONTHLY: "price_1MS2x3D0KT7zVwoYiap0JC9e",
    },
    DEVELOPER: {
      ANNUAL: "price_1MS2zBD0KT7zVwoYLNwj7Rn2",
      METERED: "price_1MS2zBD0KT7zVwoYb2rCL4qa",
      MONTHLY: "price_1MS2zBD0KT7zVwoY5J0dSGF4",
    },
    FREE: {
      ANNUAL: "price_1MS2zBD0KT7zVwoYLNwj7Rn2",
      METERED: "price_1MS2zBD0KT7zVwoYb2rCL4qa",
      MONTHLY: "price_1MS2zBD0KT7zVwoY5J0dSGF4",
    },
  },
};
