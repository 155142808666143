import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export function generateRequestId(): string {
  return uuidv4();
}

const enhancedAxios = axios;

enhancedAxios.interceptors.request.use(async (config: any) => {
  config.headers["X-Request-ID"] = generateRequestId();

  return config;
});

export default enhancedAxios;
